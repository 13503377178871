<template>
  <body-layout title="">
    <div class="actions flex" slot="actions">
      <a-button
        type="danger"
        class="mr-3"
        @click="batchDel"
        v-if="selectedRowKeys.length"
        icon="delete"
        v-has="'platform:list:button:delete'"
        >删除</a-button
      >
      <a-button type="primary" icon="hdd" class="mr-3" @click="recycleBinVisible = true" v-has="'platform:list:button:recyclebin'"
        >回收站</a-button
      >
      <a-button type="primary" @click="openNewPlatform" icon="plus" v-has="'platform:list:button:add'">新建</a-button>
    </div>
    <a-table
      v-has="'platform:list:page:view'"
      ref="table"
      size="middle"
      rowKey="platformId"
      :columns="columns"
      :dataSource="dataSource"
      :customRow="clickRow"
      :pagination="ipagination"
      :scroll="{ x: 'max-content', y: 'calc(100vh - 341px)' }"
      :loading="loading"
      :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      @change="handleTableChange"
    >
      <template slot="adminUsers" slot-scope="text, record">
        <div class="flex flex-wrap">
          <span color="blue" v-for="item in record.adminUsers" :key="item.userId">{{ item.userName }}</span>
        </div>
      </template>

      <template slot="assistantUsers" slot-scope="text, record">
        <div class="flex flex-wrap">
          <span v-for="item in record.assistantUsers" :key="item.userId">{{ item.userName }}</span>
        </div>
      </template>
      <template slot="department" slot-scope="text, record">
        {{ record.department ? record.department.departName : '无' }}
      </template>
      <template slot="status" slot-scope="text, record">
        <div>
          <span v-if="record.guideStatus">制作规范：已完成 </span>
          <span v-else>制作规范：<span style="color: red">未完成 </span></span>
          <span class="mx-2">|</span>
          <span v-if="record.ruleStatus">阶段：已完成 </span>
          <span v-else>阶段：<span style="color: red">未完成 </span></span>
          <span class="mx-2">|</span>
          <span v-if="record.termsStatus">术语表模板：已完成 </span>
          <span v-else>术语表模板：<span style="color: red">未完成 </span></span>
        </div>
      </template>
    </a-table>
    <platform-step-modal
      :open="drawerShow"
      :cur-row="curRow"
      :isForm="true"
      @closed="drawerClosed"
    ></platform-step-modal>

    <!-- 平台回收站 -->
    <platform-recycle-bin-modal :visible.sync="recycleBinVisible" @ok="modalFormOk" />
  </body-layout>
</template>

<script>
import { JeecgListMixin } from '@/mixins/JeecgListMixin'
import PlatformStepModal from './modules/PlatformStepModal'
import PlatformRecycleBinModal from '@/views/system/modules/PlatformRecycleBinModal'

export default {
  mixins: [JeecgListMixin],
  components: {
    PlatformStepModal,
    PlatformRecycleBinModal,
  },
  data() {
    return {
      recycleBinVisible: false,
      columns: [
        {
          title: '序号',
          dataIndex: 'sequenceNo',
          width: 80,
          sorter: false,
        },
        {
          title: '平台名称',
          dataIndex: 'platformName',
          width: 120,
          sorter: false,
        },
        {
          title: '统筹',
          width: 120,
          dataIndex: 'adminUsers',
          scopedSlots: { customRender: 'adminUsers' },
        },
        // {
        //   title: '辅助管理员',
        //   scopedSlots: {customRender: 'assistantUsers'},
        //   dataIndex: 'assistantUsers',
        // },
        {
          title: '所属部门',
          width: 180,
          dataIndex: 'department',
          scopedSlots: { customRender: 'department' },
        },
        {
          title: '完成度',
          scopedSlots: { customRender: 'status' },
          width: 385,
        },
      ],
      detailPath: '/system/platform/detail',
      url: {
        list: '/platform/list',
        delete: '/platform/delete',
        deleteBatch: '/platform/delete_batch',
      },
    }
  },
  methods: {
    openNewPlatform() {
      this.$router.push({ path: '/system/platform/detail/new', meta: { title: '新建平台' } })
    },
  },
  created() {},
}
</script>

<style lang='stylus'></style>