var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "body-layout",
    { attrs: { title: "" } },
    [
      _c(
        "div",
        {
          staticClass: "actions flex",
          attrs: { slot: "actions" },
          slot: "actions",
        },
        [
          _vm.selectedRowKeys.length
            ? _c(
                "a-button",
                {
                  directives: [
                    {
                      name: "has",
                      rawName: "v-has",
                      value: "platform:list:button:delete",
                      expression: "'platform:list:button:delete'",
                    },
                  ],
                  staticClass: "mr-3",
                  attrs: { type: "danger", icon: "delete" },
                  on: { click: _vm.batchDel },
                },
                [_vm._v("删除")]
              )
            : _vm._e(),
          _c(
            "a-button",
            {
              directives: [
                {
                  name: "has",
                  rawName: "v-has",
                  value: "platform:list:button:recyclebin",
                  expression: "'platform:list:button:recyclebin'",
                },
              ],
              staticClass: "mr-3",
              attrs: { type: "primary", icon: "hdd" },
              on: {
                click: function ($event) {
                  _vm.recycleBinVisible = true
                },
              },
            },
            [_vm._v("回收站")]
          ),
          _c(
            "a-button",
            {
              directives: [
                {
                  name: "has",
                  rawName: "v-has",
                  value: "platform:list:button:add",
                  expression: "'platform:list:button:add'",
                },
              ],
              attrs: { type: "primary", icon: "plus" },
              on: { click: _vm.openNewPlatform },
            },
            [_vm._v("新建")]
          ),
        ],
        1
      ),
      _c("a-table", {
        directives: [
          {
            name: "has",
            rawName: "v-has",
            value: "platform:list:page:view",
            expression: "'platform:list:page:view'",
          },
        ],
        ref: "table",
        attrs: {
          size: "middle",
          rowKey: "platformId",
          columns: _vm.columns,
          dataSource: _vm.dataSource,
          customRow: _vm.clickRow,
          pagination: _vm.ipagination,
          scroll: { x: "max-content", y: "calc(100vh - 341px)" },
          loading: _vm.loading,
          rowSelection: {
            selectedRowKeys: _vm.selectedRowKeys,
            onChange: _vm.onSelectChange,
          },
        },
        on: { change: _vm.handleTableChange },
        scopedSlots: _vm._u([
          {
            key: "adminUsers",
            fn: function (text, record) {
              return [
                _c(
                  "div",
                  { staticClass: "flex flex-wrap" },
                  _vm._l(record.adminUsers, function (item) {
                    return _c(
                      "span",
                      { key: item.userId, attrs: { color: "blue" } },
                      [_vm._v(_vm._s(item.userName))]
                    )
                  }),
                  0
                ),
              ]
            },
          },
          {
            key: "assistantUsers",
            fn: function (text, record) {
              return [
                _c(
                  "div",
                  { staticClass: "flex flex-wrap" },
                  _vm._l(record.assistantUsers, function (item) {
                    return _c("span", { key: item.userId }, [
                      _vm._v(_vm._s(item.userName)),
                    ])
                  }),
                  0
                ),
              ]
            },
          },
          {
            key: "department",
            fn: function (text, record) {
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      record.department ? record.department.departName : "无"
                    ) +
                    "\n    "
                ),
              ]
            },
          },
          {
            key: "status",
            fn: function (text, record) {
              return [
                _c("div", [
                  record.guideStatus
                    ? _c("span", [_vm._v("制作规范：已完成 ")])
                    : _c("span", [
                        _vm._v("制作规范："),
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("未完成 "),
                        ]),
                      ]),
                  _c("span", { staticClass: "mx-2" }, [_vm._v("|")]),
                  record.ruleStatus
                    ? _c("span", [_vm._v("阶段：已完成 ")])
                    : _c("span", [
                        _vm._v("阶段："),
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("未完成 "),
                        ]),
                      ]),
                  _c("span", { staticClass: "mx-2" }, [_vm._v("|")]),
                  record.termsStatus
                    ? _c("span", [_vm._v("术语表模板：已完成 ")])
                    : _c("span", [
                        _vm._v("术语表模板："),
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("未完成 "),
                        ]),
                      ]),
                ]),
              ]
            },
          },
        ]),
      }),
      _c("platform-step-modal", {
        attrs: { open: _vm.drawerShow, "cur-row": _vm.curRow, isForm: true },
        on: { closed: _vm.drawerClosed },
      }),
      _c("platform-recycle-bin-modal", {
        attrs: { visible: _vm.recycleBinVisible },
        on: {
          "update:visible": function ($event) {
            _vm.recycleBinVisible = $event
          },
          ok: _vm.modalFormOk,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }